import React, { useEffect, useState } from "react"
import HailCXLayout from "../../components/layout/hailcx-layout"
import HailCXRightImageHeader from "../../components/layout/header/hailcx-right-image-header"
import HailCXDoubleButtonFooter from "../../components/layout/footer/hailcx-double-button-footer"
import {
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from "@elastic/eui"
import { navigate } from "gatsby-link"
import { useStore } from "../../stores"
import { observer } from "mobx-react-lite"
import {
  getBookingDateUIFormatted,
  getBookingTimeUIFormatted,
} from "../../entities/utilities"
import HailCXRepairTypeAlert from "../../components/hailcx-repairtype-alert"
import "../../styles/hailcx-badges.css"
import "../../styles/hailcx-spinners.css"

const BookingSelectionPage = () => {
  const { bookingStore, customerStore } = useStore()
  const {
    availableSlots,
    booking,
    timeSelected,
    loadAvailableSlots,
    setBooking,
    setTimeSelected,
  } = bookingStore
  const { customer } = customerStore
  const [hasDataBeenLoaded, setDataHasBeenLoaded] = useState(false)

  const [scrollBookingSlotToView, setScrollBookingSlotToView] = useState(true)

  const data = {
    title: "Booking",
    primaryContent: "Choose this time",
    secondaryContent: "Other booking options",
  }

  const handlePrimaryClick = () => {
    if (customer && booking) {
      setBooking({ ...booking, customerId: customer.id })
    }

    navigate("/book/booking-selected")
  }

  const handleSecondaryClick = () => {
    navigate("/book/skip-booking")
  }

  const handleBadgeClick = (id: string, value: string, parent: string) => {
    //don't scroll when user is selecting the slot; we want to scroll to view only when user comes back to this screen from another screen
    setScrollBookingSlotToView(false)

    setTimeSelected(id)

    if (booking) {
      setBooking({ ...booking, repairDate: parent, dropOffTime: value })
    }
  }

  const asignColor = (id: string) => {
    if (id === timeSelected) {
      return "success"
    } else {
      return "default"
    }
  }

  useEffect(() => {
    renderAvailableSlots()
  }, [bookingStore])
  const renderAvailableSlots = async () => {
    if (customer) {
      await loadAvailableSlots(
        customer.registeredAtFacilityId,
        customer.repairTypeQuoted
      )
      setDataHasBeenLoaded(true)
    }
  }
  return (
    <HailCXLayout
      header={
        <HailCXRightImageHeader
          title={data.title}
          progressValue={65}
          vehicleInfo={customerStore.getVehicleInfo()}
        />
      }
      footer={
        <HailCXDoubleButtonFooter
          primaryContent={data.primaryContent}
          secondaryContent={data.secondaryContent}
          handlePrimaryClick={handlePrimaryClick}
          handleSecondaryClick={handleSecondaryClick}
          primaryButtonDisabled={timeSelected === ""}
        />
      }
    >
      <EuiText
        grow={false}
        className="eui-textLeft"
        style={{ marginRight: "auto", marginLeft: "auto" }}
      >
        <h1>Book your repair</h1>
        <p>Please choose your drop-off time.</p>
      </EuiText>
      {hasDataBeenLoaded && availableSlots ? (
        availableSlots.map(slot => (
          <div
            key={slot.repairDate}
            ref={ele => {
              if (
                ele &&
                slot.repairDate === booking?.repairDate &&
                scrollBookingSlotToView
              ) {
                ele.scrollIntoView({ behavior: "smooth" })
              }
            }}
          >
            <EuiSpacer />
            <EuiPanel>
              <EuiText
                grow={false}
                className="eui-textCenter"
                style={{ marginRight: "auto", marginLeft: "auto" }}
              >
                <h3>{getBookingDateUIFormatted(slot.repairDate)}</h3>
              </EuiText>
              <EuiSpacer />
              <EuiFlexGroup
                wrap
                responsive={false}
                gutterSize="s"
                justifyContent="spaceEvenly"
              >
                {slot.dropOffTimes?.map((time, index) => (
                  <EuiFlexItem key={`${slot.repairDate}-${index}`} grow={false}>
                    <EuiBadge
                      id={`${slot.repairDate}-${index}`}
                      color={asignColor(`${slot.repairDate}-${index}`)}
                      onClick={event => {
                        handleBadgeClick(
                          event.currentTarget.id,
                          event.currentTarget.dataset.time ?? "",
                          slot.repairDate
                        )
                      }}
                      onClickAriaLabel={time}
                      data-time={time}
                    >
                      {getBookingTimeUIFormatted(time)}
                    </EuiBadge>
                  </EuiFlexItem>
                ))}
              </EuiFlexGroup>
            </EuiPanel>
          </div>
        ))
      ) : (
        <>
          <EuiSpacer />
          <EuiSpacer />
          <EuiSpacer />
          <EuiSpacer />
          <EuiFlexGroup
            wrap
            responsive={false}
            gutterSize="s"
            justifyContent="spaceAround"
          >
            <EuiFlexItem grow={false}>
              <EuiLoadingSpinner size="xl" />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer />
          <EuiSpacer />
          <EuiSpacer />
          <EuiSpacer />
        </>
      )}
      <HailCXRepairTypeAlert />
    </HailCXLayout>
  )
}

export default observer(BookingSelectionPage)
